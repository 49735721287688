import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  LOCALE_ID,
} from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AutorizacionModule } from './nucleo/autorizacion/autorizacion.module';
import { DialogosModule } from './compartido/dialogos/dialogos.module';
import { NucleoModule } from './nucleo/nucleo.module';

import { MatDatepickerModule } from '@angular/material/datepicker';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { InterceptorAPI } from './nucleo/autenticacion/interceptor-api.class';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FORMATO_FECHA_MAT } from './compartido/utilidades/compartido.static';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { Router } from '@angular/router';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    AutorizacionModule,
    DialogosModule,
    NucleoModule,

    MatDatepickerModule,
  ],
  providers: [
    // Http Client e interceptores
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorAPI,
      multi: true,
    },

    // Localización
    { provide: LOCALE_ID, useValue: 'es' },

    // Material
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: FORMATO_FECHA_MAT },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },

    // Sentry
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
