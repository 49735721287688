/* Roles de los usuarios */
export enum RolUsuario {
  ELEVA = 'eleva',
  ORGANIZACION = 'organizacion',
  CLIENTE = 'cliente',
}

/* Permisos de los usuarios */
export type Permisos = { [objeto: string]: Permiso[] };
export enum Permiso {
  VER = 'view',
  CREAR = 'add',
  EDITAR = 'change',
  ELIMINAR = 'delete',
  VER_MASIVO = 'view_massive',
  CREAR_MASIVO = 'add_massive',

  // Verificación de Identidad
  APROBAR = 'approve',

  // Mensajes SMS
  ENVIAR = 'send',
  ENVIAR_MASIVO = 'send_massive',

  // Órdenes
  PRIORIZAR = 'prioritize',
  PREPARAR = 'prepare',

  // Domicilios
  CANCELAR = 'cancel',
  ENRUTAR = 'route',
  DESPACHAR = 'dispatch',
  REPROGRAMAR = 'reschedule',
}

/* Autorización necesaria para utilizar alguna funcionalidad */
export type Autorizacion = {
  permisos?: Permisos;
  roles?: RolUsuario[];
};

/* Estructura de Permisos en la API */
export interface PermisosDTO {
  permisos: Permisos;
  rol: RolUsuario;
}
