import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class PlacesService {
  /* API Key */
  private readonly API_KEY = 'AIzaSyCKhtQNMW0qP-CPly_PXjdLEvRnpZ2fo4U';

  /* Geocoder */
  private _geocoderObj: google.maps.Geocoder | undefined;
  private get _geocoder(): google.maps.Geocoder {
    if (!this._geocoderObj) this._geocoderObj = new google.maps.Geocoder();
    return this._geocoderObj;
  }

  /* Places */
  private _placesObj: google.maps.places.PlacesService | undefined;
  private async _places(): Promise<google.maps.places.PlacesService> {
    if (!google.maps.places) await google.maps.importLibrary('places');
    if (!this._placesObj)
      this._placesObj = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
    return this._placesObj;
  }

  /* Autocomplete */
  private _autocompleteObj: google.maps.places.AutocompleteService | undefined;
  private async _autocomplete(): Promise<google.maps.places.AutocompleteService> {
    if (!google.maps.places) await google.maps.importLibrary('places');
    if (!this._autocompleteObj)
      this._autocompleteObj = new google.maps.places.AutocompleteService();
    return this._autocompleteObj;
  }

  /* Autocomplete Token */
  private _autocompleteTokenObj:
    | google.maps.places.AutocompleteSessionToken
    | undefined;
  private async _autocompleteToken(): Promise<google.maps.places.AutocompleteSessionToken> {
    if (!google.maps.places) await google.maps.importLibrary('places');
    if (!this._autocompleteTokenObj)
      this._autocompleteTokenObj =
        new google.maps.places.AutocompleteSessionToken();
    return this._autocompleteTokenObj;
  }

  /* Address Validation */
  private readonly ADDRESS_VALIDATION_API_URL =
    'https://addressvalidation.googleapis.com/v1:validateAddress?key=' +
    this.API_KEY;

  constructor(private readonly _http: HttpClient) {}

  /**
   * Busca una dirección en la API de Google Places
   * @param {string} input - La dirección a buscar
   * @returns {Promise<google.maps.places.PlaceResult[]>} - Los resultados de la búsqueda
   * @author Juan Corral
   */
  public async buscarDireccion$(
    input: string,
  ): Promise<google.maps.places.PlaceResult[]> {
    const places = await this._places();
    return new Promise((resolve) => {
      places.findPlaceFromQuery(
        {
          query: input,
          fields: ['formatted_address', 'geometry.location'],
        },
        (results: google.maps.places.PlaceResult[] | null) => {
          resolve(results ?? []);
        },
      );
    });
  }

  /**
   * Auto-completa una dirección
   * @param {string} input - La dirección a auto-completar
   * @returns {Promise<google.maps.places.AutocompletePrediction[]>} - Las predicciones de la dirección
   * @author Juan Corral
   */
  public async autocompletarDireccion$(
    input: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const autocomplete = await this._autocomplete();
    const sessionToken = await this._autocompleteToken();
    return new Promise((resolve) => {
      autocomplete.getPlacePredictions(
        {
          input,
          sessionToken,
          types: ['address'],
          componentRestrictions: { country: 'CO' },
        },
        (results: google.maps.places.AutocompletePrediction[] | null) => {
          resolve(results ?? []);
        },
      );
    });
  }

  public async obtenerLugar$(
    placeId: string,
  ): Promise<google.maps.places.PlaceResult | null> {
    const places = await this._places();
    return new Promise((resolve) => {
      places.getDetails(
        {
          placeId,
          fields: ['geometry.location', 'address_components'], // 'formatted_address'],
        },
        (place: google.maps.places.PlaceResult | null) => {
          resolve(place);
        },
      );
    });
  }

  /**
   * Busca las coordenadas de una dirección
   * @param {string} direccion - La dirección a buscar
   * @returns {Promise<google.maps.LatLng | null>} - Las coordenadas de la dirección
   * @author Juan Corral
   */
  public async buscarCoordenadas$(
    direccion: string,
  ): Promise<google.maps.LatLng | null> {
    return new Promise((resolve) => {
      this._geocoder.geocode({ address: direccion }, (results) => {
        if (results && results.length > 0) {
          resolve(results[0].geometry.location);
        }
        resolve(null);
      });
    });
  }

  /**
   * Valida una dirección
   * @param {string} direccion - La dirección a validar
   * @returns {Promise<boolean>} - Si la dirección es válida o no
   * @author Juan Corral
   */
  public async validarDireccion$(direccion: string): Promise<boolean> {
    const body = {
      address: {
        addressLines: [direccion],
        regionCode: 'CO',
      },
    };
    const resultado = await firstValueFrom(
      this._http.post<any>(this.ADDRESS_VALIDATION_API_URL, body),
    );
    return resultado;
  }
}
