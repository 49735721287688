import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormularioComponent } from './formulario.component';
import { MaterialFileInputModule } from 'ngx-custom-material-file-input';
import { GoogleMapsModule } from '@angular/google-maps';

import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { VarDirectiveModule } from '../var-directive/var-directive.module';

@NgModule({
  declarations: [FormularioComponent],
  imports: [
    CommonModule,
    MaterialFileInputModule,
    GoogleMapsModule,

    VarDirectiveModule,

    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatIconModule,
  ],
  exports: [FormularioComponent],
})
export class FormularioModule {}
