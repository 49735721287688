<form [formGroup]="formulario" *ngIf="configuracion">
  <div
    formArrayName="campos"
    class="campos"
    [ngClass]="obtenerClases('campos')"
    *ngVar="configuracion.estilo || {} as estilo"
    [style.--width-campo]="(estilo?.widthCampo ?? 200) + 'px'"
    [style.--width-label]="(estilo?.widthLabel ?? 200) + 'px'"
  >
    <div
      *ngFor="let campo of configuracion.campos; let i = index"
      [ngClass]="obtenerClases('campo')"
      class="campo {{ 'campo-' + campo.slug }} {{ 'campo-tipo-' + campo.tipo }}"
      [class.oculto]="campo.oculto"
    >
      <!-- Label -->
      <span
        class="label"
        *ngIf="
          mostrarLabel(PosicionLabelFormulario.TOP) ||
          mostrarLabel(PosicionLabelFormulario.IZQUIERDA)
        "
      >
        {{ campo.nombre }}
        {{ campo.requerido && estilo?.marcarRequeridos ? " *" : "" }}
        {{ !campo.requerido && estilo?.marcarOpcionales ? " (Opcional)" : "" }}
      </span>

      <div class="cont-input">
        <!-- Form Field -->
        <mat-form-field
          [class.readonly]="
            campo.readonly &&
            (campo.tipo === TipoCampoFormulario.OPCION_MULTIPLE ||
              campo.tipo === TipoCampoFormulario.BOOLEANO)
          "
          [class.mat-mdc-form-field-has-icon-suffix]="
            campo.tipo === TipoCampoFormulario.FECHA ||
            campo.tipo === TipoCampoFormulario.ARCHIVO ||
            (campo.tipo === TipoCampoFormulario.TEXTO && campo.url)
          "
          appearance="outline"
          [hideRequiredMarker]="true"
        >
          <mat-label *ngIf="mostrarLabel(PosicionLabelFormulario.DENTRO)">
            {{ campo.nombre }}
            {{ campo.requerido && estilo?.marcarRequeridos ? " *" : "" }}
            {{
              !campo.requerido && estilo?.marcarOpcionales ? " (Opcional)" : ""
            }}
          </mat-label>

          <!-- Campo: Texto -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.TEXTO">
            <input
              matInput
              [placeholder]="campo.placeholder ?? ''"
              [formControlName]="i"
              [readonly]="campo.readonly || false"
              [minlength]="campo.longitudMinima || null"
              [pattern]="campo.patron || ''"
              type="{{
                campo.contrasena ? 'password' : campo.correo ? 'email' : 'text'
              }}"
            />
            <button
              matSuffix
              mat-icon-button
              *ngIf="campo.readonly && campo.url && campo.valor"
              (click)="abrirUrl(campo.valor)"
            >
              <mat-icon>open_in_new</mat-icon>
            </button>
          </ng-container>

          <!-- Campo: Número -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.NUMERO">
            <span matTextPrefix *ngIf="campo.prefijo">{{ campo.prefijo }}</span>
            <input
              matInput
              type="{{ campo.telefono ? 'tel' : 'number' }}"
              [placeholder]="campo.placeholder ?? ''"
              [formControlName]="i"
              [min]="campo.minimo || null"
              [max]="campo.maximo || null"
              [minlength]="campo.longitudMinima || null"
              [pattern]="
                campo.patron ? campo.patron : campo.telefono ? '^[0-9]*$' : ''
              "
              [readonly]="campo.readonly || false"
            />
            <span matTextSuffix *ngIf="campo.sufijo">{{ campo.sufijo }}</span>
          </ng-container>

          <!-- Campo: Fecha -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.FECHA">
            <input
              matInput
              [matDatepicker]="picker"
              [placeholder]="campo.placeholder ?? ''"
              [formControlName]="i"
              [min]="campo.minimo || null"
              [max]="campo.maximo || null"
              [readonly]="campo.readonly || false"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
              *ngIf="!campo.readonly && !campo.disabled"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </ng-container>

          <!-- Campo: Archivo -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.ARCHIVO">
            <ngx-mat-file-input
              [formControlName]="i"
              [multiple]="campo.multiples || false"
              [accept]="campo.aceptados.join(',')"
              [class.readonly]="campo.readonly"
              [valuePlaceholder]="campo.placeholder ?? ''"
            >
            </ngx-mat-file-input>
            <button matSuffix mat-icon-button>
              <mat-icon>attach_file</mat-icon>
            </button>
          </ng-container>

          <!-- Campo: Opción Múltiple -->
          <ng-container
            *ngIf="campo.tipo === TipoCampoFormulario.OPCION_MULTIPLE"
          >
            <mat-select
              [formControlName]="i"
              [placeholder]="campo.placeholder ?? ''"
            >
              <mat-option
                *ngFor="let opcion of campo.opciones"
                [value]="opcion.valor"
                (onSelectionChange)="campo.opcionOut?.next(opcion)"
              >
                {{ opcion.nombre }}
              </mat-option>
            </mat-select>
          </ng-container>

          <!-- Campo: Autocomplete -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.AUTOCOMPLETE">
            <input
              matInput
              type="text"
              [formControlName]="i"
              [placeholder]="campo.placeholder ?? ''"
              [matAutocomplete]="auto"
              [readonly]="campo.readonly || false"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let opcion of campo.opciones"
                [value]="opcion.valor"
                (onSelectionChange)="campo.opcionOut?.next(opcion)"
              >
                {{ opcion.nombre }}
              </mat-option>
            </mat-autocomplete>
          </ng-container>

          <!-- Campo: Booleano -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.BOOLEANO">
            <mat-select
              [formControlName]="i"
              [placeholder]="campo.placeholder ?? ''"
            >
              <mat-option [value]="true">Sí</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </ng-container>

          <!-- Campo: Coordenadas -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.COORDENADAS">
            <input hidden="true" matInput readonly style="display: none" />
            <span
              *ngIf="campo.readonly && !campo.valor"
              style="padding: 0 1rem"
            >
              Sin coordenadas
            </span>
            <google-map
              #mapa
              *ngIf="!campo.readonly || campo.valor"
              [class.readonly]="campo.readonly"
              [mapId]="campo.slug"
              [options]="CONFIGURACION_MAPA_FORMULARIO"
              [center]="
                campo.valor
                  ? { lat: campo.valor.latitud, lng: campo.valor.longitud }
                  : CENTRO_MAPA
              "
              width="100%"
              (mapClick)="ponerMarcadorMapa(campo, $event.latLng!.toJSON())"
            >
              <map-marker
                *ngIf="campos.controls[i].value as coords"
                [position]="{ lat: coords.latitud, lng: coords.longitud }"
              />
              <p
                *ngIf="
                  campo.placeholder &&
                  !campo.readonly &&
                  !campos.controls[i].value
                "
                class="placeholder"
              >
                {{ campo.placeholder }}
              </p>
            </google-map>
          </ng-container>
        </mat-form-field>

        <!-- Error -->
        <mat-error
          class="error"
          *ngIf="!estilo?.esconderErrores && campos.controls[i] as control"
        >
          <span *ngIf="control.invalid && (control.dirty || control.touched)">
            {{ obtenerErrorControl(campos.controls[i]) }}
          </span>
        </mat-error>
      </div>
    </div>
  </div>
</form>
