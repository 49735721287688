import { HttpErrorResponse } from '@angular/common/http';

/* Error preventivo que debe ser capturado */
export class ErrorFrontend extends Error {
  constructor(datos: any) {
    if (typeof datos === 'string') {
      super(datos);
    } else if (datos instanceof HttpErrorResponse) {
      const error = datos.error;
      if (error?.detail) super(error.detail);
      else if (error?.message) super(error.message);
      else if (error?.error) super(error.error);
      else if (error) super(JSON.stringify(error, null, 2));
      else super('Error desconocido');
    } else if (typeof datos === 'object') {
      super(JSON.stringify(datos, null, 2));
    } else {
      super('Error desconocido');
    }
  }
}

/* Error de desarrollo (para alertar al desarrollador) */
export class ErrorDesarrollo extends Error {
  constructor(mensaje: string) {
    const mensajeFinal =
      '\n' + mensaje + '\nPor favor, contacte a soporte técnico.';
    super(mensajeFinal);
  }
}
