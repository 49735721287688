import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AutorizacionDirective,
  PermisosDirective,
  RolesDirective,
} from './autorizacion.directives';

@NgModule({
  declarations: [PermisosDirective, RolesDirective, AutorizacionDirective],
  imports: [CommonModule],
  exports: [PermisosDirective, RolesDirective, AutorizacionDirective],
})
export class AutorizacionModule {}
