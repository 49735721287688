import {
  Autorizacion,
  RolUsuario,
} from 'src/app/nucleo/autorizacion/autorizacion.models';
import {
  Icono,
  Imagenes,
} from 'src/app/compartido/utilidades/compartido.static';
import { AUTORIZACION_PAGINAS } from '../autorizacion/autorizacion.static';

/* Pagina de la navegación */
export type PaginaNavegacion = {
  nombre: string;
  url: string;
  icono: Icono;
  imagen: string;
  autorizacion: Autorizacion;
};

/* Navegación de la plataforma */
export const NAVEGACION: PaginaNavegacion[] = [
  // Ad Hoc
  {
    nombre: 'Resumen del Día',
    url: '/resumen-dia',
    icono: Icono.RESUMEN_DIA,
    imagen: Imagenes.RESUMEN_DIA,
    autorizacion: AUTORIZACION_PAGINAS['Resumen del Día'],
  },

  // Órdenes
  {
    nombre: 'Crear Orden',
    url: '/ordenes/crear',
    icono: Icono.CREAR_ORDEN,
    imagen: Imagenes.CREAR_ORDEN,
    autorizacion: {
      ...AUTORIZACION_PAGINAS['Crear Orden'],
      roles: [RolUsuario.CLIENTE],
    },
  },
  {
    nombre: 'Órdenes',
    url: '/ordenes',
    icono: Icono.ORDENES,
    imagen: Imagenes.ORDENES,
    autorizacion: AUTORIZACION_PAGINAS['Órdenes'],
  },
  // {
  //   nombre: 'Preparar Órdenes',
  //   url: '/ordenes/preparar',
  //   icono: Icono.PREPARACION,
  //   imagen: Imagenes.PREPARACION,
  //   autorizacion: AUTORIZACION_PAGINAS['Preparar Órdenes'],
  // },

  // Domicilios
  {
    nombre: 'Domicilios',
    url: '/domicilios',
    icono: Icono.DOMICILIOS,
    imagen: Imagenes.DOMICILIOS,
    autorizacion: AUTORIZACION_PAGINAS['Domicilios'],
  },
  // {
  //   nombre: 'Despachar Domicilios',
  //   url: '/domicilios/despachar',
  //   icono: Icono.DESPACHO,
  //   imagen: Imagenes.DESPACHO,
  //   autorizacion: AUTORIZACION_PAGINAS['Despachar Domicilios'],
  // },
  // {
  //   nombre: 'Re-programar Domicilios',
  //   url: '/domicilios/reprogramar',
  //   icono: Icono.REPROGRAMAR,
  //   imagen: Imagenes.REPROGRAMAR,
  //   autorizacion: AUTORIZACION_PAGINAS['Re-programar Domicilios'],
  // },

  // Comunicaciones
  {
    nombre: 'Mensajes SMS',
    url: '/comunicaciones/sms',
    icono: Icono.SMS,
    imagen: Imagenes.SMS,
    autorizacion: AUTORIZACION_PAGINAS['SMS'],
  },

  // Clientes
  {
    nombre: 'Clientes',
    url: '/clientes',
    icono: Icono.CLIENTES,
    imagen: Imagenes.CLIENTES,
    autorizacion: AUTORIZACION_PAGINAS['Clientes'],
  },
  {
    nombre: 'Verificaciones de Identidad',
    url: '/clientes/verificaciones',
    icono: Icono.VERIFICACIONES,
    imagen: Imagenes.VERIFICACIONES,
    autorizacion: AUTORIZACION_PAGINAS['Verificaciones de Identidad'],
  },
  {
    nombre: 'Mi Perfil',
    url: '/clientes/perfil',
    icono: Icono.PERFIL,
    imagen: Imagenes.PERFIL,
    autorizacion: {
      ...AUTORIZACION_PAGINAS['Ver Cliente'],
      roles: [RolUsuario.CLIENTE],
    },
  },

  // Paquetes
  {
    nombre: 'Paquetes',
    url: '/paquetes',
    icono: Icono.PAQUETES,
    imagen: Imagenes.PAQUETES,
    autorizacion: AUTORIZACION_PAGINAS['Paquetes'],
  },
];
