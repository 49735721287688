import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { OrganizacionDTO, DireccionDTO, ClienteDTO } from './clientes.models';
import { FormateableTabla } from 'src/app/compartido/tabla-asincrona/utilidades/tabla-asincrona.models';

/* Cliente */
export class Cliente implements FormateableTabla {
  constructor(
    public id: number,
    public tipoIdentificacion: string,
    public identificacion: string,
    public nombre: string,
    public telefono: number | null,
    public celular: number | null,
    public correo: string | null,
    public organizacion: OrganizacionDTO,
    public direcciones: DireccionDTO[],
  ) {}

  /**
   * Devuelve los datos del cliente con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      id: this.id,
      tipoIdentificacion: this.tipoIdentificacion,
      identificacion: this.identificacion,
      nombre: this.nombre,
      telefono: this.telefono,
      celular: this.celular,
      correo: this.correo,
      organizacion: this.organizacion.nombre,
    };
  }

  /**
   * Crea un objeto Cliente a partir de los datos del API
   * @param {ClienteDTO} cliente - El cliente como llega del API
   * @returns {Cliente} - El objeto Cliente creado
   * @author Juan Corral
   */
  public static fromDTO(cliente: ClienteDTO): Cliente {
    return new Cliente(
      cliente.id,
      cliente.tipo_identificacion,
      cliente.identificacion,
      cliente.nombre,
      cliente.telefono,
      cliente.celular,
      cliente.correo,
      cliente.organizacion,
      cliente.direcciones,
    );
  }
}
