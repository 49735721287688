import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { OrganizacionDTO } from '../../clientes/utilidades/clientes.models';
import { ListaDTO } from '../../compartido/utilidades/compartido.models';
import { VehiculoDTO } from 'src/app/domicilios/utilidades/domicilios.models';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class OrganizacionService {
  /* Organizaciones */
  private _organizaciones: OrganizacionDTO[] | undefined;
  private _organizacionesPromise$: Promise<OrganizacionDTO[]> | undefined;
  public get organizaciones$(): Promise<OrganizacionDTO[]> {
    if (this._organizacionesPromise$) return this._organizacionesPromise$;
    if (this._organizaciones) return Promise.resolve(this._organizaciones);
    this._organizacionesPromise$ = this._obtenerOrganizaciones$().then(
      (organizaciones: OrganizacionDTO[]) => {
        this._organizaciones = organizaciones;
        this._organizacionesPromise$ = undefined;
        return organizaciones;
      },
    );
    return this._organizacionesPromise$;
  }

  /* Vehículos */
  private _vehiculos: VehiculoDTO[] | undefined;
  private _vehiculosPromise$: Promise<VehiculoDTO[]> | undefined;
  public get vehiculos$(): Promise<VehiculoDTO[]> {
    if (this._vehiculosPromise$) return this._vehiculosPromise$;
    if (this._vehiculos) return Promise.resolve(this._vehiculos);
    this._vehiculosPromise$ = this._obtenerVehiculos$().then(
      (vehiculos: VehiculoDTO[]) => {
        this._vehiculos = vehiculos;
        this._vehiculosPromise$ = undefined;
        return vehiculos;
      },
    );
    return this._vehiculosPromise$;
  }

  constructor(private readonly http: HttpClient) {}

  /**
   * Obtiene la lista de organizaciones del servidor.
   * @returns {Promise<OrganizacionDTO[]>} - La lista de organizaciones como llega del servidor (promise).
   * @author Juan Corral
   */
  private async _obtenerOrganizaciones$(): Promise<OrganizacionDTO[]> {
    return firstValueFrom(
      this.http.get<ListaDTO<OrganizacionDTO>>('organizaciones/').pipe(
        map((res: ListaDTO<OrganizacionDTO>) => res.results),
        catchError(() => of([])),
      ),
    );
  }

  /**
   * Obtiene la lista de vehículos del servidor.
   * @returns {Promise<VehiculoDTO[]>} - La lista de vehículos como llega del servidor (promise).
   * @author Juan Corral
   */
  private _obtenerVehiculos$(): Promise<VehiculoDTO[]> {
    return firstValueFrom(
      this.http.get<ListaDTO<VehiculoDTO>>('domicilios/vehiculos/').pipe(
        map((res: ListaDTO<VehiculoDTO>) => res.results),
        catchError(() => of([])),
      ),
    );
  }

  /**
   * Limpia las organizaciones y vehículos almacenados en la instancia.
   * @author Juan Corral
   */
  public limpiarOrganizaciones(): void {
    this._organizaciones = undefined;
    this._organizacionesPromise$ = undefined;
    this._vehiculos = undefined;
    this._vehiculosPromise$ = undefined;
  }
}
