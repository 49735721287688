import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';
import {
  ClienteDTO,
  DireccionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { BienvenidaComponent } from '../../adhoc/componentes/bienvenida/bienvenida.component';

@Injectable({ providedIn: 'root' })
export class NotificacionesService {
  /* Notificaciones */
  public notificaciones$: Observable<Notificacion[]> = new BehaviorSubject([]);

  constructor(
    private readonly _autenticacionService: AutenticacionService,
    private readonly _dialogosService: DialogosService,
  ) {}

  /**
   * Actualiza las notificaciones.
   * @author Juan Corral
   */
  public actualizarNotificaciones(): void {
    this._obtenerNotificaciones$().then((notificaciones) => {
      (this.notificaciones$ as BehaviorSubject<Notificacion[]>).next(
        notificaciones,
      );
    });
  }

  /**
   * Obtiene las notificaciones del servidor.
   * @returns {Promise<Notificacion[]>} - Las notificaciones del servidor (promise).
   * @author Juan Corral
   */
  private async _obtenerNotificaciones$(): Promise<Notificacion[]> {
    const notificaciones: Notificacion[] = [];
    const usuario = await this._autenticacionService.usuario$;

    // Cliente incompleto
    if (
      usuario!.rol === RolUsuario.CLIENTE &&
      this._clienteIncompleto(usuario!.cliente!)
    ) {
      // notificaciones.push({
      //   mensaje: 'Completa tu perfil para poder realizar pedidos',
      //   tipo: TipoNotificacion.ADVERTENCIA,
      //   icono: Icono.PERFIL,
      //   pagina: `/clientes/perfil`,
      // });

      const config: ConfiguracionDialogoVistaPrevia = {
        componente: BienvenidaComponent,
        output: 'completado',
      };
      this._dialogosService.abrirVistaPrevia$(config, { disableClose: true });
    }

    return notificaciones;
  }

  /**
   * Devuelve si el cliente está incompleto.
   * @param {ClienteDTO} cliente - El cliente a verificar.
   * @returns {boolean} - Si el cliente está incompleto o no.
   * @author Juan Corral
   */
  private _clienteIncompleto(cliente: ClienteDTO): boolean {
    const direccionIncompleta = (direccion: DireccionDTO) => {
      return (
        !direccion.detalles ||
        !direccion.barrio ||
        !direccion.localidad ||
        !direccion.ciudad ||
        !direccion.departamento
      );
    };
    const direcciones = cliente.direcciones.filter(
      (direccion) => direccion.creacion_manual,
    );
    return (
      !cliente ||
      !cliente.direcciones ||
      direcciones.length === 0 ||
      direcciones.some((direccion) => direccionIncompleta(direccion))
    );
  }
}

/* Interfaz para las notificaciones */
export interface Notificacion {
  mensaje: string;
  tipo: TipoNotificacion;
  icono: Icono;
  pagina?: string;
}

/* Tipos de notificación */
export enum TipoNotificacion {
  ERROR = 'error',
  ADVERTENCIA = 'advertencia',
  INFO = 'info',
}
