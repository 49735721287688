/* Formato de fecha general */
export const FORMATO_FECHA = 'YYYY-MM-DD';

/* Formato de fecha para material */
export const FORMATO_FECHA_MAT = {
  parse: { dateInput: FORMATO_FECHA },
  display: {
    dateInput: FORMATO_FECHA,
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/* Formato de fecha para el pipe de date */
export const FORMATO_FECHA_PIPE = 'YYYY-MM-dd';

/* Icono de la plataforma */
export enum Icono {
  // Verificaciones de identidad
  APROBACION = 'verified',

  // Órdenes
  ORDENES = 'receipt', // insert_drive_file // description
  CREAR_ORDEN = 'note_add',
  PRIORIZAR = 'keyboard_double_arrow_up',
  PREPARACION = 'shopping_bag', // 'shopping_basket',

  // Domicilios
  DOMICILIOS = 'location_on', // 'house' // 'local_shipping',
  DESPACHO = 'local_shipping', // 'qr_code_scanner',
  ETIQUETAS = 'receipt_long', // 'sticky_note_2'
  REPROGRAMAR = 'event_repeat', // 'repartition',
  ENRUTAMIENTO = 'route',
  ENVIO = 'local_shipping',
  ENTREGA = 'house',
  LEGALIZACION = 'gavel',

  // Paquetes
  PAQUETES = 'inventory',

  // Comunicaciones
  SMS = 'sms',
  ENVIAR_SMS = 'send',

  // Clientes
  CLIENTES = 'group',
  VERIFICACIONES = 'fingerprint', // verified_user // how_to_reg
  PERFIL = 'person',

  // Acciones
  CREAR = 'add',
  VER = 'visibility',
  EDITAR = 'mode_edit',
  ELIMINAR = 'delete',
  GUARDAR = 'save',
  CANCELAR = 'cancel',
  DESCARGAR = 'download',
  SUBIR = 'upload',
  CERRAR = 'close',

  // Otros
  ERROR = 'error',
  ERROR_SINCRONIZACION = 'sync_problem',
  ADVERTENCIA = 'warning',

  RESUMEN_DIA = 'today',
}

/* Imágenes de la plataforma */
export enum Imagenes {
  // Órdenes
  ORDENES = 'ordenes',
  CREAR_ORDEN = 'crear_orden',
  PREPARACION = 'preparacion2',

  // Domicilios
  DOMICILIOS = 'domicilios',
  DESPACHO = 'despacho',
  REPROGRAMAR = 'reprogramar',

  // Paquetes
  PAQUETES = 'paquetes2',

  // Comunicaciones
  SMS = 'sms',

  // Clientes
  CLIENTES = 'clientes',
  VERIFICACIONES = 'verificaciones2',
  PERFIL = 'clientes',

  // Otros
  RESUMEN_DIA = 'resumen_dia',
}
