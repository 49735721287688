import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { ClienteDTO, ClienteEditarDTO } from './clientes.models';
import {
  Parametros,
  ListaDTO,
} from '../../compartido/utilidades/compartido.models';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Cliente } from './cliente.class';
import { PoblacionDTO } from 'src/app/adhoc/paginas/ramedicas/utilidades/ramedicas.models';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class ClientesService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Crea el cliente en el servidor.
   * @param {ClienteDTO} cliente - El cliente a crear.
   * @returns {Promise<Cliente>} - El cliente creado (promise).
   * @throws {ErrorFrontend} - Si hubo un error al crear el cliente.
   * @author Juan Corral
   */
  public crearCliente$(cliente: ClienteDTO): Promise<Cliente> {
    return firstValueFrom(
      this.http.post<ClienteDTO>('clientes/', cliente).pipe(
        map((cliente: ClienteDTO) => Cliente.fromDTO(cliente)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Actualiza el cliente en el servidor.
   * @param {number} id - El id del cliente a actualizar.
   * @param {ClienteEditarDTO} cliente - El cliente con los datos actualizados.
   * @returns {Promise<Cliente>} - El cliente actualizado (promise).
   * @throws {ErrorFrontend} - Si hubo un error al actualizar el cliente.
   * @author Juan Corral
   */
  public actualizarCliente$(
    id: number,
    cliente: ClienteEditarDTO,
  ): Promise<Cliente> {
    return firstValueFrom(
      this.http.patch<ClienteDTO>('clientes/' + id, cliente).pipe(
        map((cliente: ClienteDTO) => Cliente.fromDTO(cliente)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Obtiene información del cliente del servidor.
   * @param {number} id - El id del cliente a obtener.
   * @returns {Promise<Cliente | undefined>} - El cliente del servidor o undefined si hubo un error (promise).
   * @author Juan Corral
   */
  public async obtenerCliente$(id: number): Promise<Cliente | undefined> {
    return firstValueFrom(
      this.http.get('clientes/' + id).pipe(
        map((cliente: any) => Cliente.fromDTO(cliente)),
        catchError(() => of(undefined)),
      ),
    );
  }

  /**
   * Obtiene los clientes del servidor.
   * @param {Parametros} params - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<ClienteDTO>>} - Los clientes obtenidos del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener los clientes.
   * @author Juan Corral
   */
  public obtenerClientes$(params: Parametros): Promise<ListaDTO<ClienteDTO>> {
    return firstValueFrom(
      this.http.get<ListaDTO<ClienteDTO>>('clientes/', { params }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Obtiene la población de un cliente.
   * @param {string} identificacion - La identificación del cliente.
   * @returns {Promise<PoblacionDTO>} - La población del cliente (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener la población del cliente.
   * @author Juan Corral
   */
  public async obtenerPoblacion$(
    identificacion: string,
  ): Promise<PoblacionDTO> {
    const params = { identificacion };
    return firstValueFrom(
      this.http.get<PoblacionDTO>('clientes/poblacion', { params }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }
}
