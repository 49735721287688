import { Autorizacion, Permiso } from './autorizacion.models';

/* Autorización necesaria para acceder a las páginas de la plataforma */
export const AUTORIZACION_PAGINAS: Record<string, Autorizacion> = {
  // Clientes
  Clientes: { permisos: { cliente: [Permiso.VER_MASIVO] } },
  'Verificaciones de Identidad': {
    permisos: {
      verificacionidentidad: [Permiso.VER_MASIVO, Permiso.APROBAR],
    },
  },
  'Verificación de Identidad': {
    permisos: { verificacionidentidad: [Permiso.VER, Permiso.APROBAR] },
  },
  'Ver Cliente': { permisos: { cliente: [Permiso.VER] } },

  // Comunicaciones
  SMS: { permisos: { mensajesms: [Permiso.VER_MASIVO] } },
  'Enviar SMS': {
    permisos: { mensajesms: [Permiso.CREAR_MASIVO, Permiso.ENVIAR_MASIVO] },
  },

  // Domicilios
  Domicilios: { permisos: { domicilio: [Permiso.VER_MASIVO] } },
  'Errores Simpli': { permisos: { domicilio: [Permiso.VER_MASIVO] } },
  'Enrutar Domicilios': { permisos: { domicilio: [Permiso.ENRUTAR] } },
  'Despachar Domicilios': {
    permisos: {
      domicilio: [Permiso.VER_MASIVO, Permiso.VER, Permiso.DESPACHAR],
    },
  },
  'Despachar Domicilio': {
    permisos: { domicilio: [Permiso.VER, Permiso.DESPACHAR] },
  },
  'Re-programar Domicilios': {
    permisos: {
      domicilio: [Permiso.VER_MASIVO, Permiso.VER, Permiso.REPROGRAMAR],
    },
  },
  'Re-programar Domicilio': {
    permisos: { domicilio: [Permiso.VER, Permiso.REPROGRAMAR] },
  },
  'Ver Domicilio': { permisos: { domicilio: [Permiso.VER] } },

  // Ordenes
  Órdenes: { permisos: { orden: [Permiso.VER_MASIVO] } },
  'Importar Órdenes': { permisos: { orden: [Permiso.CREAR_MASIVO] } },
  'Crear Orden': { permisos: { orden: [Permiso.CREAR] } },
  'Preparar Órdenes': {
    permisos: { orden: [Permiso.VER_MASIVO, Permiso.VER, Permiso.PREPARAR] },
  },
  'Preparar Orden': { permisos: { orden: [Permiso.VER, Permiso.PREPARAR] } },
  'Ver Orden': { permisos: { orden: [Permiso.VER] } },

  // Paquetes
  Paquetes: { permisos: { paquete: [Permiso.VER_MASIVO] } },
  'Agregar Paquetes': {
    permisos: { paquete: [Permiso.CREAR_MASIVO] },
  },

  // Ad Hoc
  'Resumen del Día': { permisos: { orden: [Permiso.VER_MASIVO] } },
};
