import { Injectable } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import {
  CampoFormulario,
  ConfiguracionFormulario,
  OpcionCampo,
  TipoCampoFormulario,
} from '../../compartido/formulario/utilidades/formulario.models';
import { obtenerCampo } from '../../compartido/formulario/utilidades/formulario.utils';
import { ErrorDesarrollo } from '../../compartido/utilidades/error.utils';
import { FormateadorDireccion } from '../../compartido/utilidades/formateador-direccion.class';
import { OrganizacionService } from './organizacion.service';
import { PlacesService } from './places.service';
import {
  DireccionDTO,
  OrganizacionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { valorNumérico } from 'src/app/compartido/utilidades/valores.utils';
import {
  OPCIONES_FARMACIAS_EMCO_SALUD,
  OPCIONES_FARMACIAS_VIDAMEDICAL,
  OPCIONES_FARMACIAS_RAMEDICAS,
} from 'src/app/ordenes/paginas/importar-ordenes/utilidades/importar-ordenes.static';
import { VehiculoDTO } from 'src/app/domicilios/utilidades/domicilios.models';

@Injectable({ providedIn: 'root', deps: [OrganizacionService, PlacesService] })
export class FormulariosService {
  constructor(
    private readonly _organizacionService: OrganizacionService,
    private readonly _placesService: PlacesService,
  ) {}

  /**
   * Agrega los observables de valorIn a los campos de un formulario.
   * @param {ConfiguracionFormulario | CampoFormulario[]} fuente - El formulario o los campos a configurar.
   * @param {string[]} campos - Los campos a los que se les agregará el observable.
   * @author Juan Corral
   */
  public agregarValorIn(
    fuente: ConfiguracionFormulario | CampoFormulario[],
    campos: string[],
  ): void {
    const camposArray = Array.isArray(fuente) ? fuente : fuente.campos;
    for (const campo of camposArray) {
      if (campos.includes(campo.slug) && !campo.valorIn)
        campo.valorIn = new Subject<string>();
    }
  }

  /**
   * Agrega las opciones de organización al campo de un formulario.
   * @param {ConfiguracionFormulario | CampoFormulario[]} fuente - El formulario o los campos a configurar.
   * @param {boolean} incluirTodos [Opcional] - Indica si se debe incluir la opción "Todos" en el campo.
   * @returns {Promise<OrganizacionDTO[]>} - Las organizaciones obtenidas (promise).
   * @author Juan Corral
   */
  public async configurarOpcionesOrganizacion$(
    fuente: ConfiguracionFormulario | CampoFormulario[],
    incluirTodos: boolean = false,
  ): Promise<OrganizacionDTO[]> {
    const campos = Array.isArray(fuente) ? fuente : fuente.campos;

    // Obtener y validar campo
    const campoOrg = obtenerCampo(campos, 'organizacion');
    if (!campoOrg)
      throw new ErrorDesarrollo('No se encontró el campo organización');
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo de dirección debe ser de tipo OPCION_MULTIPLE',
      );

    // Crear los observables
    if (!campoOrg.opcionesIn)
      campoOrg.opcionesIn = new Subject<OpcionCampo<string | number>[]>();
    if (!campoOrg.valorIn) campoOrg.valorIn = new Subject<number>();

    // Obtener las organizaciones
    const organizaciones = await this._organizacionService.organizaciones$;

    // Configurar las opciones del campo
    const opciones: OpcionCampo<string | number>[] = organizaciones.map(
      (org: OrganizacionDTO) => ({ nombre: org.nombre, valor: org.id }),
    );
    if (incluirTodos) opciones.unshift({ nombre: 'Todos', valor: NaN });
    campoOrg.opcionesIn.next(opciones);

    // Seleccionar la primera organización si solo hay una
    if (organizaciones.length == 1) {
      campoOrg.valorIn.next(organizaciones[0].id);
      campoOrg.readonly = true;
    }

    return organizaciones;
  }

  /**
   * Configura un formulario de dirección con auto-complete y asociación de campos.
   * @param {ConfiguracionFormulario | CampoFormulario[]} fuente - El formulario o los campos a configurar.
   * @returns {Promise<Subscription>} - La suscripción a los observables de los campos (promise).
   * @author Juan Corral
   */
  public async configurarFormularioDireccion$(
    fuente: ConfiguracionFormulario | CampoFormulario[],
    datos?: DireccionDTO,
  ): Promise<Subscription> {
    const suscripcion = new Subscription();
    const campos = Array.isArray(fuente) ? fuente : fuente.campos;

    // Obtener y validar campos
    const campoDireccion = obtenerCampo(campos, 'direccion');
    if (!campoDireccion)
      throw new ErrorDesarrollo('No se encontró el campo dirección');
    if (campoDireccion.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
      throw new ErrorDesarrollo(
        'El campo dirección debe ser de tipo AUTOCOMPLETE',
      );
    const campoFormateada = obtenerCampo(campos, 'direccion_formateada');
    if (campoFormateada && campoFormateada.tipo !== TipoCampoFormulario.TEXTO)
      throw new ErrorDesarrollo('El campo formateada debe ser de tipo TEXTO');
    const campoDetalles = obtenerCampo(campos, 'detalles');
    if (campoDetalles && campoDetalles.tipo !== TipoCampoFormulario.TEXTO)
      throw new ErrorDesarrollo('El campo detalles debe ser de tipo TEXTO');
    const campoBarrio = obtenerCampo(campos, 'barrio');
    if (campoBarrio && campoBarrio.tipo !== TipoCampoFormulario.TEXTO)
      throw new ErrorDesarrollo('El campo barrio debe ser de tipo TEXTO');
    const campoLocalidad = obtenerCampo(campos, 'localidad');
    if (campoLocalidad && campoLocalidad.tipo !== TipoCampoFormulario.TEXTO)
      throw new ErrorDesarrollo('El campo localidad debe ser de tipo TEXTO');
    const campoCiudad = obtenerCampo(campos, 'ciudad');
    if (campoCiudad && campoCiudad.tipo !== TipoCampoFormulario.TEXTO)
      throw new ErrorDesarrollo('El campo ciudad debe ser de tipo TEXTO');
    const campoDep = obtenerCampo(campos, 'departamento');
    if (campoDep && campoDep.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo departamento debe ser de tipo OPCION_MULTIPLE',
      );
    const campoLatitud = obtenerCampo(campos, 'latitud');
    if (campoLatitud && campoLatitud.tipo !== TipoCampoFormulario.NUMERO)
      throw new ErrorDesarrollo('El campo latitud debe ser de tipo NUMERO');
    const campoLongitud = obtenerCampo(campos, 'longitud');
    if (campoLongitud && campoLongitud.tipo !== TipoCampoFormulario.NUMERO)
      throw new ErrorDesarrollo('El campo longitud debe ser de tipo NUMERO');
    const campoCoords = obtenerCampo(campos, 'coordenadas');
    if (campoCoords && campoCoords.tipo !== TipoCampoFormulario.COORDENADAS)
      throw new ErrorDesarrollo(
        'El campo coordenadas debe ser de tipo COORDENADAS',
      );

    // Poblar los campos con los datos, si se proporcionan
    if (datos) {
      const datosPlanos = datos as Record<string, any>;
      for (const campo of campos) {
        if (campo.slug !== 'coordenadas') campo.valor = datosPlanos[campo.slug];
      }
      if (campoLatitud?.valor && campoLongitud?.valor && campoCoords) {
        const latitud = valorNumérico(campoLatitud.valor, 'float');
        const longitud = valorNumérico(campoLongitud.valor, 'float');
        if (latitud && longitud) campoCoords.valor = { latitud, longitud };
      }
    }

    // Agregar observables a los campos
    if (!campoDireccion.valorOut)
      campoDireccion.valorOut = new Subject<string | number>();
    if (!campoDireccion.opcionesIn)
      campoDireccion.opcionesIn = new Subject<OpcionCampo<string | number>[]>();
    if (!campoDireccion.opcionOut)
      campoDireccion.opcionOut = new Subject<OpcionCampo<string | number>>();
    if (campoFormateada && !campoFormateada.valorIn)
      campoFormateada.valorIn = new Subject<string>();
    if (campoBarrio && !campoBarrio.valorIn)
      campoBarrio.valorIn = new Subject<string>();
    if (campoLocalidad && !campoLocalidad.valorIn)
      campoLocalidad.valorIn = new Subject<string>();
    if (campoCiudad && !campoCiudad.valorIn)
      campoCiudad.valorIn = new Subject<string>();
    if (campoDep && !campoDep.valorIn) campoDep.valorIn = new Subject<string>();
    if (campoLatitud && !campoLatitud.valorIn)
      campoLatitud.valorIn = new Subject<string>();
    if (campoLongitud && !campoLongitud.valorIn)
      campoLongitud.valorIn = new Subject<string>();
    if (campoCoords) {
      if (!campoCoords.valorIn)
        campoCoords.valorIn = new Subject<{
          latitud: string;
          longitud: string;
        }>();
      if (!campoCoords.valorOut)
        campoCoords.valorOut = new Subject<{
          latitud: string;
          longitud: string;
        }>();
    }

    // Auto-completar la dirección
    const escucharDireccion = campoDireccion.valorOut
      .pipe(debounceTime(700))
      .subscribe(async (direccion: string) => {
        const lugares: google.maps.places.AutocompletePrediction[] =
          await this._placesService.autocompletarDireccion$(direccion);
        const opcionesDireccion = lugares.map(
          (s: google.maps.places.AutocompletePrediction) =>
            ({
              nombre: s.description,
              valor: s.structured_formatting.main_text,
              extra: s.place_id,
            }) as OpcionCampo<string>,
        );
        opcionesDireccion.unshift({
          nombre: direccion,
          valor: direccion,
          extra: null,
        });
        campoDireccion.opcionesIn!.next(opcionesDireccion);
      });
    suscripcion.add(escucharDireccion);

    // Crear conexión entre direccion y otros campos
    const seleccionarDireccion = campoDireccion.opcionOut!.subscribe(
      async (opcion: OpcionCampo<string | number>) => {
        if (!opcion.extra) return;
        const lugar = await this._placesService.obtenerLugar$(opcion.extra);
        if (!lugar) return;

        // Actualizar la dirección formateada
        if (campoFormateada) {
          const formateador = new FormateadorDireccion(opcion.valor as string);
          const formateada = formateador.formatearDireccion();
          if (formateada)
            campoFormateada.valorIn!.next(formateador.direccionFormateada);
          // campoDireccionFormateada.valorIn!.next(lugar.formatted_address);
        }

        // Actualizar el barrio
        if (campoBarrio) {
          const barrio = lugar.address_components?.find((c) =>
            c.types.includes('neighborhood'),
          );
          if (barrio) campoBarrio.valorIn!.next(barrio.long_name);
        }

        // Actualizar la localidad
        if (campoLocalidad) {
          const localidad = lugar.address_components?.find((c) =>
            c.types.includes('sublocality'),
          );
          if (localidad) campoLocalidad.valorIn!.next(localidad.long_name);
        }

        // Actualizar la ciudad
        if (campoCiudad) {
          const ciudad = lugar.address_components?.find((c) =>
            c.types.includes('locality'),
          );
          if (ciudad) campoCiudad.valorIn!.next(ciudad.long_name);
        }

        // Actualizar el departamento
        if (campoDep) {
          const departamento = lugar.address_components?.find((c) =>
            c.types.includes('administrative_area_level_1'),
          );
          if (departamento) campoDep.valorIn!.next(departamento.long_name);
        }

        // Actualizar la latitud
        if (campoLatitud) {
          const latitud = lugar.geometry?.location?.lat();
          if (latitud) campoLatitud.valorIn!.next(latitud.toString());
        }

        // Actualizar la longitud
        if (campoLongitud) {
          const longitud = lugar.geometry?.location?.lng();
          if (longitud) campoLongitud.valorIn!.next(longitud.toString());
        }

        // Actualizar las coordenadas
        if (campoCoords) {
          const coordenadas = lugar.geometry?.location;
          if (coordenadas) {
            campoCoords.valorIn!.next({
              latitud: coordenadas.lat(),
              longitud: coordenadas.lng(),
            });
          }
        }
      },
    );
    suscripcion.add(seleccionarDireccion);

    // Crear conexión entre coordenadas, latitud y longitud
    if (campoLatitud && campoLongitud && campoCoords) {
      const actualizarCoordenadas = campoCoords.valorOut!.subscribe(
        (coordenadas) => {
          campoLatitud.valorIn!.next(coordenadas.latitud);
          campoLongitud.valorIn!.next(coordenadas.longitud);
        },
      );
      suscripcion.add(actualizarCoordenadas);
    }

    return suscripcion;
  }

  /**
   * Configura las opciones del campo farmacia en función de la organización.
   * @param {ConfiguracionFormulario} configFormulario - La configuración del formulario.
   * @returns {Subscription} - La suscripción al observable de la organización (promise).
   * @author Juan Corral
   */
  public async configurarOpcionesFarmacia$(
    fuente: ConfiguracionFormulario | CampoFormulario[],
  ): Promise<Subscription> {
    const campos = Array.isArray(fuente) ? fuente : fuente.campos;

    // Obtener y validar campos
    const campoOrg = obtenerCampo(campos, 'organizacion');
    if (!campoOrg)
      throw new ErrorDesarrollo('No se encontró el campo organización');
    const campoFarmacia = obtenerCampo(campos, 'farmacia')!;
    if (!campoFarmacia)
      throw new ErrorDesarrollo('No se encontró el campo farmacia');
    if (campoFarmacia.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo farmacia debe ser de tipo OPCION_MULTIPLE',
      );

    // Crear observables
    if (!campoOrg.valorOut) campoOrg.valorOut = new Subject<number>();

    // Obtener organizaciones
    const organizaciones = await this._organizacionService.organizaciones$;

    // Configurar las opciones del campo farmacia
    const escucharOrg = campoOrg.valorOut.subscribe((valor: number) => {
      const organizacion = organizaciones.find((org) => org.id === valor);
      const nombre = organizacion?.nombre;
      campoFarmacia.oculto =
        nombre !== 'Vidamedical' &&
        nombre !== 'Emco Salud' &&
        nombre !== 'Ramedicas';
      campoFarmacia.opciones =
        nombre === 'Vidamedical'
          ? OPCIONES_FARMACIAS_VIDAMEDICAL
          : nombre === 'Emco Salud'
            ? OPCIONES_FARMACIAS_EMCO_SALUD
            : nombre === 'Ramedicas'
              ? OPCIONES_FARMACIAS_RAMEDICAS
              : [];
    });

    return escucharOrg;
  }

  /**
   * Agrega las opciones de vehículo al campo de un formulario.
   * @param {ConfiguracionFormulario | CampoFormulario[]} fuente - El formulario o los campos a configurar.
   * @param {boolean} incluirTodos [Opcional] - Indica si se debe incluir la opción "Todos" en el campo.
   * @returns {Promise<VehiculoDTO[]>} - Los vehículos obtenidas (promise).
   * @author Juan Corral
   */
  public async configurarOpcionesVehiculo$(
    fuente: ConfiguracionFormulario | CampoFormulario[],
    incluirTodos: boolean = false,
  ): Promise<VehiculoDTO[]> {
    const campos = Array.isArray(fuente) ? fuente : fuente.campos;

    // Obtener y validar campo
    const campoVehiculo = obtenerCampo(campos, 'vehiculo');
    if (!campoVehiculo)
      throw new ErrorDesarrollo('No se encontró el campo vehículo');
    if (campoVehiculo.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo vehículo debe ser de tipo OPCION_MULTIPLE',
      );

    // Crear los observables
    if (!campoVehiculo.opcionesIn)
      campoVehiculo.opcionesIn = new Subject<OpcionCampo<string | number>[]>();

    // Obtener las organizaciones
    const vehiculos = await this._organizacionService.vehiculos$;

    // Configurar las opciones del campo
    const opciones: OpcionCampo<string | number>[] = vehiculos.map(
      (org: VehiculoDTO) => ({ nombre: org.nombre, valor: org.id }),
    );
    if (incluirTodos) opciones.unshift({ nombre: 'Todos', valor: NaN });
    campoVehiculo.opcionesIn!.next(opciones);

    return vehiculos;
  }
}
