import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  autenticacionGuard,
  autenticadoGuard,
} from './nucleo/autenticacion/autenticacion.guards';
import { autorizacionGuard } from './nucleo/autorizacion/autorizacion.guards';

const routes: Routes = [
  {
    path: 'ramedicas',
    loadChildren: () =>
      import('./adhoc/paginas/ramedicas/ramedicas.module').then(
        (m) => m.RamedicasModule,
      ),
    title: 'Ramedicas',
  },
  {
    path: 'ingreso',
    loadChildren: () =>
      import('./ingreso/ingreso.module').then((m) => m.IngresoModule),
    title: 'Ingreso',
    canActivate: [autenticadoGuard],
    canActivateChild: [autenticadoGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./nucleo/nucleo.module').then((m) => m.NucleoModule),
    canActivate: [autenticacionGuard],
    canActivateChild: [autenticacionGuard, autorizacionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
