<mat-nav-list>
  <ng-container *ngFor="let elemento of NAVEGACION">
    <a
      mat-list-item
      *autorizacion="elemento.autorizacion"
      [routerLink]="elemento.url"
    >
      <mat-icon matListItemIcon>{{ elemento.icono }}</mat-icon>
      <span matListItemTitle>{{ elemento.nombre }}</span>
    </a>
  </ng-container>
</mat-nav-list>
