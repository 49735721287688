import {
  ConfiguracionFormulario,
  DisplayFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { OPCIONES_DEPARTAMENTOS_FORMULARIO } from 'src/app/compartido/formulario/utilidades/formulario.static';

export const CONFIGURACION_FORMULARIO_BIENVENIDA: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'Dirección',
      slug: 'direccion',
      tipo: TipoCampoFormulario.AUTOCOMPLETE,
      opciones: [], // Se obtiene del servidor
      requerido: true,
    },
    {
      nombre: 'Formateada',
      slug: 'direccion_formateada',
      tipo: TipoCampoFormulario.TEXTO,
      oculto: true,
    },
    {
      nombre: 'Detalles',
      slug: 'detalles',
      tipo: TipoCampoFormulario.TEXTO,
      placeholder: 'Apartamento, casa, torre, etc.',
      requerido: true,
    },
    {
      nombre: 'Barrio',
      slug: 'barrio',
      tipo: TipoCampoFormulario.TEXTO,
      requerido: true,
    },
    {
      nombre: 'Localidad',
      slug: 'localidad',
      tipo: TipoCampoFormulario.TEXTO,
      requerido: true,
    },
    {
      nombre: 'Ciudad',
      slug: 'ciudad',
      tipo: TipoCampoFormulario.TEXTO,
      requerido: true,
    },
    {
      nombre: 'Departamento',
      slug: 'departamento',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: OPCIONES_DEPARTAMENTOS_FORMULARIO,
      requerido: true,
    },
    {
      nombre: 'Coordenadas',
      slug: 'coordenadas',
      tipo: TipoCampoFormulario.COORDENADAS,
      placeholder: 'Seleccione una ubicación',
      requerido: true,
    },
  ],
  estilo: {
    display: DisplayFormulario.GRID,
    posicionLabel: PosicionLabelFormulario.TOP,
    widthCampo: 250,
    widthLabel: 250,
    marcarRequeridos: true,
  },
};
