<div
  *ngIf="configuracion.botonCerrar || configuracion.url"
  class="botones"
  [class.superpuestos]="configuracion.botonesSuperpuestos"
>
  <button
    mat-icon-button
    [matTooltip]="'Abrir en nueva ventana'"
    tabindex="-1"
    *ngIf="configuracion.url !== undefined"
    (click)="abrirUrl()"
  >
    <mat-icon>open_in_new</mat-icon>
  </button>
  <button
    mat-icon-button
    tabindex="-1"
    *ngIf="configuracion.botonCerrar"
    (click)="dialogRef.close(null)"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<ng-template #contenedor></ng-template>
