import { OpcionCampo } from './formulario.models';

/* Configuración del mapa para crear dirección */
export const CONFIGURACION_MAPA_FORMULARIO: google.maps.MapOptions = {
  zoom: 18,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: true,
  scaleControl: false,
  rotateControl: false,
  clickableIcons: false,
  draggableCursor: 'pointer',
};

/* Centro de Bogotá */
export const CENTRO_BOGOTA = { lat: 4.60971, lng: -74.08175 };

/* Opciones para campo tipo de identificación */
export const OPCIONES_TIPO_IDENTIFICACION_FORMULARIO: OpcionCampo<string>[] = [
  { valor: 'CC', nombre: 'Cédula de Ciudadanía' },
  { valor: 'CE', nombre: 'Cédula de Extranjería' },
  { valor: 'RC', nombre: 'Registro Civil' },
  { valor: 'TI', nombre: 'Tarjeta de Identidad' },
  { valor: 'PA', nombre: 'Pasaporte' },
  { valor: 'PE', nombre: 'Permiso especial de permanencia' },
  { valor: 'PT', nombre: 'Permiso temporal de permanencia' },
  { valor: 'NIT', nombre: 'NIT' },
  { valor: 'Otro', nombre: 'Otro' },
];

/* Opciones para campo departamento */
export const OPCIONES_DEPARTAMENTOS_FORMULARIO: OpcionCampo<string>[] = [
  { valor: 'Amazonas', nombre: 'Amazonas' },
  { valor: 'Antioquia', nombre: 'Antioquia' },
  { valor: 'Arauca', nombre: 'Arauca' },
  { valor: 'Atlántico', nombre: 'Atlántico' },
  { valor: 'Bogotá', nombre: 'Bogotá D.C.' },
  { valor: 'Bolívar', nombre: 'Bolívar' },
  { valor: 'Boyacá', nombre: 'Boyacá' },
  { valor: 'Caldas', nombre: 'Caldas' },
  { valor: 'Caquetá', nombre: 'Caquetá' },
  { valor: 'Casanare', nombre: 'Casanare' },
  { valor: 'Cauca', nombre: 'Cauca' },
  { valor: 'Cesar', nombre: 'Cesar' },
  { valor: 'Chocó', nombre: 'Chocó' },
  { valor: 'Córdoba', nombre: 'Córdoba' },
  { valor: 'Cundinamarca', nombre: 'Cundinamarca' },
  { valor: 'Guainía', nombre: 'Guainía' },
  { valor: 'Guaviare', nombre: 'Guaviare' },
  { valor: 'Huila', nombre: 'Huila' },
  { valor: 'La Guajira', nombre: 'La Guajira' },
  { valor: 'Magdalena', nombre: 'Magdalena' },
  { valor: 'Meta', nombre: 'Meta' },
  { valor: 'Nariño', nombre: 'Nariño' },
  { valor: 'Norte de Santander', nombre: 'Norte de Santander' },
  { valor: 'Putumayo', nombre: 'Putumayo' },
  { valor: 'Quindío', nombre: 'Quindío' },
  { valor: 'Risaralda', nombre: 'Risaralda' },
  {
    valor: 'San Andrés y Providencia',
    nombre: 'San Andrés y Providencia',
  },
  { valor: 'Santander', nombre: 'Santander' },
  { valor: 'Sucre', nombre: 'Sucre' },
  { valor: 'Tolima', nombre: 'Tolima' },
  { valor: 'Valle del Cauca', nombre: 'Valle del Cauca' },
  { valor: 'Vaupés', nombre: 'Vaupés' },
  { valor: 'Vichada', nombre: 'Vichada' },
];
